'use client';

import { shopVar } from "@/lib/shopStyles";
import { Product } from "@/lib/TicketApi";
import { useLanguage } from '@/providers/LanguageProvider';
import { useCart } from "@/providers/CartProvider";
import { RiAddLine, RiSubtractLine } from "@remixicon/react";

interface ProductCartButtonProps {
    product: Product;
}

export default function ProductAvailable({product}: ProductCartButtonProps) {
    const {setProductQuantity, selectedQuantity, loading, totalForProduct, productError, cart} = useCart();

    const { currentLanguage: lang } = useLanguage();

    let limit = product.maxQuantity;
    if(product.isHidden){
        if(cart === null || cart.appliedCouponCode === null || cart.appliedCouponCode.unlockProducts === null){
            limit = 0;
        } else {
            limit = cart.appliedCouponCode.unlockProducts.products.find(p => p.productId === product.productId)?.quantity ?? 0;
        }
    }
    // console.log(product.);

    const handleDecrease = () => {
        const currentQuantity = selectedQuantity(product.productId);
        if (currentQuantity > 0) {
            setProductQuantity(product.productId, currentQuantity - 1);
        }
    };

    const handleIncrease = () => {
        const currentQuantity = selectedQuantity(product.productId);
        if(currentQuantity >= limit){
            return;
        }
        setProductQuantity(product.productId, currentQuantity + 1);
    };

    const forProduct = totalForProduct(product.productId);

    return (
        <>
        <div className="flex justify-between py-2">
            <div className="text-base font-bold">
                {forProduct !== null ? forProduct.totalInclFee.display(lang) : ''}
            </div>
            <div className="flex items-center select-none">
                {forProduct && forProduct.quantity > 0 && <>
                    <button
                        className="p-1 rounded-full"
                        style={{
                            opacity: loading ? 0.5 : 1,
                            color: 'var(--shop-removeFromCartButtonTextColor)',
                            backgroundColor: 'var(--shop-removeFromCartButtonColor)',
                        }}
                        disabled={loading} 
                        onClick={handleDecrease}>
                        <RiSubtractLine size={20} color={'white'}/>
                    </button>
                    <span className="font-bold text-lg px-2" style={{
                        width: '2.5rem',
                        textAlign: 'center',
                        color: 'var(--shop-productQuantityTextColor)',
                    }}>{selectedQuantity(product.productId)}</span>
                </>}
    
                <button
                    className="p-1 rounded-full"
                    style={{
                        opacity:selectedQuantity(product.productId) >= limit ? 0.1 : loading ? 0.5 : 1,
                        color: shopVar('--shop-addToCartButtonTextColor'),
                        backgroundColor: shopVar('--shop-addToCartButtonColor'),
                    }}
                    disabled={loading || selectedQuantity(product.productId) >= limit} 
                    onClick={handleIncrease}>
                    <RiAddLine size={20} color={'white'}/>
                </button>
            </div>
        </div>
            
            {productError(product.productId) && 
            <div className="font-bold text-red-500 mt-2 -ml-2 p-2 bg-red-100 rounded-md">
                {productError(product.productId)}
            </div>}
        </>
    )
}