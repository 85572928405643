import {FormPartComponent, FormPartType} from "@/components/purchase/details/FormParts/FormParts"
import {FunctionComponent, useEffect, useState} from "react";
import {OrderDetails} from "@/components/purchase/details/FormParts/OrderDetails";
import { useCart } from '@/providers/CartProvider';
import { Panel } from "@/components/shared/Panel";

export function Form() {

    const {cart: order, setFormValid, formState, setFormState} = useCart();

    // const [formState, setFormState] = useState({});
    const [formPartValidStatus, setFormPartValidStatus] = useState({})

    const formPartComponents: {[k in FormPartType]: FunctionComponent<FormPartComponent<any>>} = {
        [FormPartType.OrderDetail]: OrderDetails,
    }

    useEffect(() => {
        setFormState(order?.answers ?? {});
    }, [order?.answers]);

    if(order === undefined || order === null) {
        return <></>;
    }

    // @ts-ignore
    const allFormPartsAreValid = Object.keys(formPartValidStatus).every((key) => formPartValidStatus[key]);

    useEffect(() => {
        setFormValid(allFormPartsAreValid);
    }, [allFormPartsAreValid]);

    
    // function submitForm() {
    //     setFormAnswers(formState).then(() => {
    //         console.log('Form submitted');
    //     });
    // }

    return (
        <Panel>
            {Object.keys(order.form)
                .map((key) => {
                        // @ts-ignore
                    let formPart = order.form[key];
                        // @ts-ignore
                        const Component = formPartComponents[formPart.type];
                        return (
                            <Component key={key}
                                       formPart={formPart}
                                       // @ts-ignore
                                       answers={order.answers[formPart.type] ?? {}}
                                       errors={order.errors[formPart.type] ?? []}
                                       onValidStatusChange={(formPartType: string, value: boolean) => setFormPartValidStatus({
                                ...formPartValidStatus,
                                [formPartType]: value
                            })}
                                       onValueChange={(formPartType: string, value: string|object) => {
                                           setFormState({
                                               ...formState,
                                               [formPartType]: value
                                           });
                                       }}/>
                        )
                    }
                )}
        </Panel>
    )
}
