import React, { useState } from 'react';
import TicketPerf from '../../../assets/order/ticket-perf.svg';
import MobileTicket from '../../../assets/order/mobile-ticket.svg';
import QR from '../../../assets/order/qr.svg';
// import GetTicket from '../../../assets/order/get-ticket.svg';
import { TicketStatus, Ticket as TicketType } from '@/lib/OrderApi';
import { Dialog } from '../shared/Dialog';
import ScanOrDownloadTicketModal from './ScanOrDownloadTicketModal';
// import clsx from 'clsx';
// import Sold from '../../../assets/order/sold.svg';
import { RiShare2Line } from '@remixicon/react';
import { Money } from '@/lib/money';
import copy from 'copy-to-clipboard';
interface TicketProps {
  ticket: TicketType;
}

import { shareOnMobile } from "react-mobile-share";
import { useEvent } from '@/providers/EventProvider';
import { useLanguage } from '@/providers/LanguageProvider';
import * as m from "@/paraglide/messages.js"

const Ticket: React.FC<TicketProps> = ({ ticket }) => {

  const [showCopied, setShowCopied] = useState(false);
  const {event} = useEvent();

  const { currentLanguage: lang } = useLanguage();
  
  const [ticketScanModalOpen, setTicketScanModalOpen] = useState(false);
  
  const isMobile = /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent);

  function renderTicketStatusIcon(ticket: TicketType): React.ReactNode | Iterable<React.ReactNode> {
    switch(ticket.ticketStatus) {
      case TicketStatus.available:
        return <button className='cursor-pointer flex flex-col items-center hover:scale-105 transition-all' onClick={() => {
          setTicketScanModalOpen(true);
        }}>
          <img src={QR} alt="" className='h-12 py-2'/>
          <span className='text-sm text-center tracking-tight'>{m.order_ticket_show_ticket()}</span>
        </button>
      case TicketStatus.sealed:
        return <img src={MobileTicket} alt="" className='h-12 py-1'/>
      case TicketStatus.claimed:
        return <img src={MobileTicket} alt="" className='h-12 py-1'/>
    }

    // Mobile ticket
    // <img src={MobileTicket} alt="" className='h-12 py-1'/>
    // <span className='text-sm text-center tracking-tight'>Activate mobile ticket</span>
  }

  return (
    <div className="mt-4 flex h-[100px]">
      <div className='bg-white w-[100px] flex flex-col items-center justify-center p-2 rounded-md rounded-tr-none rounded-br-none'>
        {renderTicketStatusIcon(ticket)}
      </div>
      <img src={TicketPerf} alt="" className='h-[100px]'/>
      <div className='bg-white grow -ml-[0.8px] flex flex-col justify-between rounded-md rounded-tl-none rounded-bl-none'>
        <div className='flex px-4 items-center justify-between border-b border-gray-200 grow'>
          <div className='flex items-center'>
            <span className='text-sm leading-normal font-semibold'>{ticket.productName}</span> <span className='ml-1 text-xs tracking-tight'> {ticket.numberOfProductType}/{ticket.totalOfProductType}</span>
          </div>
          <Dialog open={ticketScanModalOpen} onOpenChange={setTicketScanModalOpen}>
            <ScanOrDownloadTicketModal ticket={ticket} />
          </Dialog>
          {/* <div className={clsx('flex items-center   ', ticket.ticketStatus === TicketStatus.available ? 'opacity-100 hover:underline cursor-pointer' : 'opacity-30 cursor-not-allowed')} onClick={() => {
            if(ticket.ticketStatus !== TicketStatus.available) {
              return
            }
            setTicketScanModalOpen(true);
          }}>
            <img src={GetTicket} alt="" className='w-6 h-6'/>
            <span className='ml-2 tracking-tight font-semibold whitespace-nowrap'>Get Ticket</span>
          </div> */}
        </div>
        <div className='flex px-4 items-center justify-between grow'>
          <span className='text-base leading-normal font-semibold'>{Money.fromInteger(ticket.price.amount, ticket.price.currency).add(Money.fromInteger(ticket.serviceFee.amount, ticket.serviceFee.currency)).display(lang)}</span>
          <div className='flex itemx-center justify-end gap-4'>
            <div className='flex flex-col items-end'>
              <button onClick={() => {
                const url = `${window.location.origin}/shared-ticket/${ticket.ticketId}`;
                if(isMobile){
                  shareOnMobile({
                    text: m.share_dialog_text({
                      productName: ticket.productName,
                      eventName: event?.name ?? '',
                    }),
                    url: url,
                    title: m.share_dialog_title({eventName: event?.name ?? ''}),
                    images: [],
                  })
                } else {
                  copy(url, {
                    message: m.copied_to_clipboard(),
                  });
                  setShowCopied(true);
                  // hide after 5 seconds
                  setTimeout(() => {
                    setShowCopied(false);
                  }, 3000);
              }}} className='flex items-center hover:underline hover:cursor-pointer hover:bg-gray-100 rounded-md p-2 -m-2'>
                <RiShare2Line className='w-6 h-6'/>
                <span className='ml-2 tracking-tight font-semibold'>{m.order_ticket_share()}</span>
                
              </button>
              {showCopied && <div className='pt-1 text-xs text-gray-500'>{m.url_copied_to_clipboard()}</div>}
            </div>
            {/* <div className='flex items-center'>
              <img src={Sold} alt="" className='w-5 h-5'/>
              <span className='ml-2 tracking-tight font-semibold'>Sell</span>
            </div> */}
          </div>
          
        </div>
      </div>
    </div>
  );
};

export { Ticket };