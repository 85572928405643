import React from 'react';
import { shopVar } from './lib/shopStyles';
import { CartStep, useCart } from './providers/CartProvider';
import {Header} from './components/header/Header';
import {ProductPage} from './components/purchase/product-pages/ProductPage';
import {Form} from './components/purchase/details/Form';
import {Checkout} from './components/purchase/checkout/checkout';
import {TotalAndCtaComponent} from './components/shared/TotalAndCtaComponent';
import { useEvent } from './providers/EventProvider';
import '@fontsource/inter/400.css';  // Regular weight
import '@fontsource/inter/700.css';  // Bold weight
import '@fontsource/inter/100.css';  // Thin weight
import '@fontsource/inter/200.css';  // Extra Light weight
import '@fontsource/inter/300.css';  // Light weight
import '@fontsource/inter/500.css';  // Medium weight
import '@fontsource/inter/600.css';  // Semi Bold weight

export const TicketShop: React.FC = () => {
    const { cartStep} = useCart();
    const { event } = useEvent();

    const productPage = event.productPages[0];

    function renderContent(): React.ReactNode | Iterable<React.ReactNode> {
        switch (cartStep) {
            case CartStep.Tickets:
                if (!productPage) {
                    return <div>No product page found</div>;
                }
                return <ProductPage productPage={productPage}/>;
            case CartStep.Order:
                return <Form/>;
            case CartStep.Checkout:
                return <Checkout/>;
        }
    }

    return (
        <>
        <div className='min-w-[252px] md:min-w-[552px] font-sans' style={{color: shopVar('--shop-textColor')}}>
            <Header/>
            
            {renderContent()}

            {(cartStep === CartStep.Order || cartStep === CartStep.Tickets) && (<TotalAndCtaComponent/>)}
        </div>        
        <div className='flex items-center justify-center flex-col pt-2'>
            <a href="https://fastlane.events" target='_blank'><img src="https://imagedelivery.net/PmH0OH_6tQBdO_uod3xHAg/d4d591f0-a4c1-4a92-66f5-d01140628000/public" className='w-36 py-2' alt='Ticketshop' /></a>
        </div>
        </>
  );
};