
import React from 'react';
import { Header } from '@/components/header/Header';
import { EventProvider } from '@/providers/EventProvider';
import { Event } from '@/lib/TicketApi';

interface OrderPageShellProps {
  event: Event;
  children: React.ReactNode;
}

const OrderPageShell: React.FC<OrderPageShellProps> = ({ event, children }) => {
  return (
    <div className='bg-gradient-to-b from-[#9bb7ff] to-[#a7dbc7] sm:p-10 min-h-screen font-sans'>
      <EventProvider event={event}>
        <div className='max-w-[640px] mx-auto'>
          <div className='bg-white rounded-3xl rounded-bl-md rounded-br-md'>
            <Header />
          </div>
         {children}
        </div>
      </EventProvider>
    </div>
  );
};

export default OrderPageShell;
