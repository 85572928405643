export interface OrderedList<T> {
    [index: number]: T;
}

export interface OrganisationLegalInformation {
    addressLine: string;
    chamberOfCommerceNumber: string;
    companyName: string;
    contactEmail: string;
    contactPhone: string;
    termsAndConditionsUrl: string;
    vatNumber: string;
}

export interface Shop {
    slug: string,
    language: string,
    languages: string[],
    style: ShopStyle,
    events: Event[],
    defaultCurrency: string,
    paymentMethods: PaymentMethod[],
    organisationLegalInformation: OrganisationLegalInformation,
    // form: OrderedList<FormComponent>,
}

export interface PaymentMethod {
    id: string;
    fee: ServiceFee;
    name: string;
    image: string;
}

export interface FormComponent {
    type: string;
}

export interface Event {
    eventId: string;
    slug: string;
    name: string;
    headerImage?: string;
    description?: string;
    location: {
        name: string;
    },
    startDate: string;
    endDate: string;
    productPages: ProductPage[];
}

export interface ProductPage {
    name: string;
    slug: string;
    icon: IconName;
    productSections: ProductSection[];
    canAdvanceToNextPageWithoutSelectingProduct: boolean;
}

export interface ProductSection {
    id: string;
    name?: string;
    openedDefault: boolean;
    canCollapse: boolean;
    products: Product[];
}

export interface Media<T> {
    type: string;
    img: T;
}

export type Icon = Media<string>;
export type Image = Media<string>;
export type Carousel = Media<string[]>;

export enum IconName {
    Ticket = "ticket",
    CreditCard = "credit-card",
    PlusFilled = "plus-filled",
    Bed = "bed",
}

export interface NotListed {
    type: "notListed";
}

export interface NotAvailable {
    type: "notAvailable";
}

export interface Available {
    type: "available";
}

export interface AvailableBetween {
    type: "available_between";
    availableFrom: string | null;
    availableUntil: string | null;
    showStartDateTime: boolean;
    showSoldOutOnEndDateTime: boolean;
}

export type Availability = NotListed | NotAvailable | Available | AvailableBetween;

// interface WhenOtherProductNotAvailable {
//     type: "when_other_product_not_available";
//     productId: string;
// }

export interface Product {
    productId: string;
    name: string;
    description?: string;
    photo: Icon|Image|Carousel;
    price: MoneyValues;
    serviceFee?: ServiceFee;
    inventoryStatus: "available"|"all_reserved"|"sold_out";
    availability: Availability;
    statusMessage?: string;
    startSale: Date;
    endSale: Date;
    maxQuantity: number;
    minQuantity: number;
    stepQuantity: number;
    isHidden: boolean;
}

export interface MoneyValues {
    amount: number;
    currency: string;
}

export interface ServiceFee {
    name: string;
    amountPerUnit?: MoneyValues;
    percentage?: number;

}

export interface ShopStyle {
    panelBackgroundColor?: string;
    textColor?: string;
    removeFromCartButtonColor?: string;
    removeFromCartButtonTextColor?: string;
    addToCartButtonColor?: string;
    addToCartButtonTextColor?: string;
    productErrorMessageBackground?: string;
    productErrorMessageText?: string;
    ctaButtonColor?: string;
    ctaButtonTextColor?: string;
    borderColor?: string;
}

export interface ProductQuantity {
    productId: string;
    quantity: number;
}

export enum PaymentStatus {
    Pending = 'pending',
    Authorized = 'authorized',
    Paid = 'paid',
    Cancelled = 'cancelled',
    Expired = 'expired',
    Declined = 'declined'
}

export interface Payment {
    status: PaymentStatus;
    methodId: string;
    startedAt: string;
}

export interface Cart {
    cartId: string;
    shopSlug: string;
    products: ProductQuantity[];
    timedOut: boolean;
    form: OrderedList<FormComponent>;
    answers: object;
    errors: string[]|string[][];
    payments: {[paymentId: string]: Payment;}
    isCheckedOut: boolean;
    pendingPayment: string|null;
    orderId: string|null;
    appliedCouponCode: Coupon|null;
}

export interface Coupon {
    code: string;
    unlockProducts: null|UnlockProducts;
}

export interface UnlockProducts {
    products: ProductUnlock[];
}

export interface ProductUnlock {
    productId: string;
    quantity: number;
}

export interface Order {
    orderId: string;
    shopStyle: ShopStyle;
    tickets: Ticket[];
    contactDetails: {
        firstName: string;
        lastName: string;
        email: string;
    };
}

interface BaseTicket {
    ticketId: string;
    productId: string;
    productName: string;
    productPrice: MoneyValues;
}

export interface AvailableTicket extends BaseTicket {
    ticketStatus: TicketStatus.available;
    canBeAddedToWallet: boolean;
    canBeDownloadedAsPdf: boolean;
    barcode: string;
}

export interface SealedTicket extends BaseTicket {
    ticketStatus: TicketStatus.sealed;
    availableAt: string;
    // scans: Scan[];
}

export interface ClaimableTicket extends BaseTicket {
    ticketStatus: TicketStatus.claimable;
    claimForm: OrderedList<FormComponent>;
    canClaimSelf: boolean;
}

export interface ClaimedTicket extends BaseTicket {
    ticketStatus: TicketStatus.claimed;
    claimedAt: string;
    claimedBy: string;
}

export type Ticket = AvailableTicket | ClaimableTicket | ClaimedTicket | SealedTicket;

export enum TicketStatus {
    available = "available",
    sealed = "sealed",
    claimed = "claimed",
    claimable = "claimable",
}

export interface PaymentResponse {
    type: "redirect"|"embed"|"no_payment_required";
    url?: string;
}

export function findElementInOrderedList<T>(orderedList: { [index: number]: T }, predicate: (item: T) => boolean): T | undefined {
    const keys = Object.keys(orderedList).map(key => parseInt(key, 10));
    const key = keys.find(key => predicate(orderedList[key]));
    return key !== undefined ? orderedList[key] : undefined;
}

export function getFirstElement<T>(orderedList: { [index: number]: T }): T | undefined {
    const sortedKeys = Object.keys(orderedList).map(key => parseInt(key, 10)).sort((a, b) => a - b);
    const firstKey = sortedKeys[0];
    return orderedList[firstKey];
}

export function getElementAfter<T>(orderedList: { [index: number]: T }, predicate: T): T | undefined {
    const keys = Object.keys(orderedList).map(key => parseInt(key, 10));
    const sortedKeys = keys.sort((a, b) => a - b);

    const index = sortedKeys.findIndex(key => orderedList[key] === predicate);
    if (index === -1) {
        return undefined;
    }
    const nextKey = sortedKeys[index + 1];
    return nextKey !== undefined ? orderedList[nextKey] : undefined;
}

export function getProduct(shop: Shop, productId: string): Product | undefined {
    for (const eventKey of Object.keys(shop.events)) {
        const event = shop.events[parseInt(eventKey, 10)];
        for (const productPageKey of Object.keys(event.productPages)) {
            const productPage = event.productPages[parseInt(productPageKey, 10)];
            for (const productSectionKey of Object.keys(productPage.productSections)) {
                const productSection = productPage.productSections[parseInt(productSectionKey, 10)];
                const product = findElementInOrderedList(productSection.products, product => product.productId === productId);
                if (product !== undefined) {
                    return product;
                }
            }
        }
    }
    return undefined;
}

export enum ReserveProductError {
    NotForSale = "NotForSale",
    SoldOut = "SoldOut",
    NotEnoughInStock = "NotEnoughInStock",
    NotAvailable = "NotAvailable",
    NotInCart = "NotInCart",
    CartTimeout = "CartTimeout",
}

export class TicketApi {
    constructor(
        private readonly baseUrl: string,
        private readonly shopSlug: string,
    ) {
    }
    public async loadCart(cartId: string): Promise<Cart> {
        const res = await fetch(`${this.baseUrl}/shop/${this.shopSlug}/cart/${cartId}`, { cache: "no-cache" });
        if (res.status === 404) {
            throw new Error("Cart not found");
        }
        return await res.json();
    }

    public async applyCoupon(cartId: string, couponCode: string): Promise<Cart|string> {
        const res = await fetch(`${this.baseUrl}/shop/${this.shopSlug}/cart/${cartId}/apply-coupon`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                code: couponCode,
            }),
        });

        if(res.status === 422){
            const body = await res.json();
            return body.message;
        }


        return await res.json();
    }

    public async reserveProducts(cartId: string, products: ProductQuantity[]): Promise<Cart|ReserveProductError> {
        // api/v1/shop/foo/cart/01HRQGEH9X7SJNGK4DCT9024SZ/reserve-tickets
        const res = await fetch(`${this.baseUrl}/shop/${this.shopSlug}/cart/${cartId}/reserve-tickets`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                products: products,
            }),
        });

        if(res.status === 400){
            return ReserveProductError.CartTimeout;
        }

        if (res.status === 500) {
            throw new Error("Cart not found");
        }

        if (res.status === 404) {
            throw new Error("Cart not found");
        }

        return await res.json();
    }

    public async loadShop(language: string, unlockedProductIds: string[]): Promise<Shop> {
        console.log(`${this.baseUrl}/shop/${this.shopSlug}`);
        // build query params
        const queryParams = new URLSearchParams();
        queryParams.set("language", language);
        if(unlockedProductIds.length > 0) {
            queryParams.set("unlocked_products", unlockedProductIds.join(","));
        }

        const res = await fetch(`${this.baseUrl}/shop/${this.shopSlug}?${queryParams.toString()}`, {cache: "no-cache"});
        return await res.json();
    }

    public async setFormAnswers(cartId: string, answers: object): Promise<Cart> {
        const res = await fetch(`${this.baseUrl}/shop/${this.shopSlug}/cart/${cartId}/submit-form`, {
            method: "POST",
            headers: {"Content-Type": "application/json",},
            body: JSON.stringify(answers),
        });
        return await res.json();
    }

    public async checkout(cartId: string, eventId: string, paymentMethodId: string|null, redirectUrl: string|null = null): Promise<PaymentResponse> {
        const currentUrlWithoutHash = window.location.origin + window.location.pathname;

        const res = await fetch(`${this.baseUrl}/shop/${this.shopSlug}/cart/${cartId}/checkout`, {
            method: "POST",
            headers: {"Content-Type": "application/json",},
            body: JSON.stringify({
                eventId: eventId,
                redirect: redirectUrl ?? currentUrlWithoutHash,
                paymentMethodId: paymentMethodId,
            }),
        });
        return await res.json();
    }

    public async forceCancelPendingPayment(cartId: string, paymentId: string): Promise<void> {
        await fetch(`${this.baseUrl}/shop/${this.shopSlug}/cart/${cartId}/cancel-pending-payment`, {
            method: "POST",
            headers: {"Content-Type": "application/json",},
            body: JSON.stringify({
                paymentId: paymentId,
            }),
        });    
    }

    public async getOrder(orderId: string): Promise<Order> {
        const res = await fetch(`${this.baseUrl}/orders/${orderId}`, {cache: "no-cache"});
        return await res.json();
    }
}
