import React from 'react';
import { ShopProvider } from './providers/ShopProvider';
import {StyleOverwrites} from './providers/StyleOverwrites';
import { CartProvider } from './providers/CartProvider';

interface TicketShopProps {
  shopSlug: string;
  eventSlug: string;
  isWidget?: boolean;
}
import { EventContainer } from './EventContainer';
import { LanguageProvider } from './providers/LanguageProvider';

export const TicketShopContainer: React.FC<TicketShopProps> = ({ shopSlug, eventSlug, isWidget = false }) => {
  return (
    <LanguageProvider>
      <ShopProvider shopSlug={shopSlug} baseUrl={import.meta.env.VITE_API_URL} isWidget={isWidget}>
        <StyleOverwrites>
          <CartProvider>
            <EventContainer eventSlug={eventSlug}/>
          </CartProvider>
        </StyleOverwrites>
      </ShopProvider>
    </LanguageProvider>
  );
};