export enum FormPartType {
    OrderDetail = "orderDetails",
}

export type FormPartComponent<FP extends SupportedFormParts> = {
    formPart: FP,
    onValueChange: (formPartType: string, value: string|object) => void,
    onValidStatusChange: (formPartType: string, value: boolean) => void,
    answers: object[]|string[],
    errors: string[]|string[][],
};

export type SupportedFormParts = OrderDetail;

export type FormPart<T extends FormPartType, TS extends {} = {}> = {
    type: T;
} & TS;

export type OrderDetail = FormPart<FormPartType.OrderDetail, {
    label: string,
    email: {
        label: string,
    },
    first_name: {
        label: string,
    },
    last_name: {
        label: string,
    },
}>
