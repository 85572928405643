import { Event, FormComponent, MoneyValues, OrderedList, ShopStyle } from "./TicketApi";

export interface Order {
    orderId: string;
    shopStyle: ShopStyle;
    tickets: Ticket[];
    event: Event;
    contactDetails: {
        firstName: string;
        lastName: string;
        email: string;
    };
}

export interface SharedTicket {
    orderId: string;
    shopStyle: ShopStyle;
    ticket: Ticket;
    event: Event;
}

interface BaseTicket {
    ticketId: string;
    productId: string;
    productName: string;
    price: MoneyValues;
    serviceFee: MoneyValues;
    numberOfProductType: number;
    totalOfProductType: number;
}

export interface AvailableTicket extends BaseTicket {
    ticketStatus: TicketStatus.available;
    wallet_download_url: string|null;
    pdf_download_url: string|null;
    barcode: string;
}

export interface SealedTicket extends BaseTicket {
    ticketStatus: TicketStatus.sealed;
    availableAt: string;
    // scans: Scan[];
}

export interface ClaimableTicket extends BaseTicket {
    ticketStatus: TicketStatus.claimable;
    claimForm: OrderedList<FormComponent>;
    canClaimSelf: boolean;
}

export interface ClaimedTicket extends BaseTicket {
    ticketStatus: TicketStatus.claimed;
    claimedAt: string;
    claimedBy: string;
}

export type Ticket = AvailableTicket | ClaimableTicket | ClaimedTicket | SealedTicket;

export enum TicketStatus {
    available = "available",
    sealed = "sealed",
    claimed = "claimed",
    claimable = "claimable",
}

export class OrderApi {
    constructor(
        private readonly baseUrl: string,
    ) {
    }
    public async getOrder(orderId: string): Promise<Order> {
        const res = await fetch(`${this.baseUrl}/orders/${orderId}`, {cache: "no-cache"});
        if(res.status === 404) {
            throw new Error("Order not found");
        }
        return await res.json();
    }

    public async getSharedTicket(ticketId: string): Promise<SharedTicket> {
        const res = await fetch(`${this.baseUrl}/tickets/shared/${ticketId}`, {cache: "no-cache"});
        return await res.json();
    }
}
